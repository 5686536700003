import BaseBean from "@/utils/BaseBean";

export interface IUpdateInfoDataObj {
    utilInst:UpdateInfoUtil
    refMap:Map<string,any>
    form:any
    suffixIcon:string
}
export default class UpdateInfoUtil extends BaseBean{
    public dataObj:IUpdateInfoDataObj;

    constructor(proxy:any,dataObj:IUpdateInfoDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //修改个人信息
    public async updateInfo():Promise<void>{
        let res=await this.utils.ToolsProviderApi.clientUpdateInfo(this.dataObj.form);
        if(res.code=='0000')this.proxy.$message('修改成功');
        else if(res.message)this.proxy.$message(res.message);
    }
    //不允许特殊字符(只能是字母何数字)
    public validForbid(value:any):void{
        // value = value.replace(/[^a-zA-Z0-9\u4e00-\u9fa5\u3002\uff0c\uff1a\uff08\uff09\uff1f\u201c\u201d\u3001\uff01,/$#@%^&*.,<>//， !:()?_""—-]/g,"");
        value=value.replace(/[^\a-\z\A-\Z0-9]/g,'');
        return value;
    }
}